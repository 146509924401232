<template>
  <loading v-if="loading" centered />
  <div
    v-else
    class="columns is-multiline is-marginless has-padding-50 has-padding-bottom-400"
  >
    <div v-if="$_.get(site, 'isDeleted')" class="column is-12">
      <site-deleted-notification :site="site" />
    </div>

    <div class="column is-12">
      <collapse title="Tasks">
        <tasks-table
          :site-id="siteId"
          include-deleted
          admin-context
          @createTask="createTask"
        />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Notes">
        <site-notes :site-id="siteId" admin-context />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Secrets">
        <site-secrets :site-id="siteId" />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="File backups">
        <file-backups-card :site-id="siteId" class="borderless" />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Database backups">
        <db-backups-card :site-id="siteId" class="borderless" headerless />
      </collapse>
    </div>

    <div class="column is-12">
      <site-details :site-id="siteId" />
    </div>

    <div class="column is-12">
      <site-delegates :site-id="siteId" />
    </div>

    <div class="column is-12">
      <collapse title="Reports">
        <reports-table :site-id="siteId" />
      </collapse>
    </div>

    <div class="column is-12">
      <collapse title="Activity logs">
        <logs-table :site-id="siteId" admin-context />
      </collapse>
    </div>

    <div class="column is-12">
      <site-tokens :site-id="siteId" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminSite",
  components: {
    "tasks-table": () => import("@shared/tasks/_tasksTable"),
    "site-notes": () => import("@shared/sites/_siteNotes"),
    "site-secrets": () => import("@shared/sites/_siteSecrets"),
    "file-backups-card": () => import("@shared/backups/_fileBackupsCard"),
    "db-backups-card": () => import("@shared/backups/_dbBackupsCard"),
    "site-details": () => import("@views/sites/site/settings/details"),
    "site-deleted-notification": () =>
      import("@views/sites/site/_siteDeletedNotification"),
    "site-delegates": () => import("@views/sites/site/settings/delegates"),
    "logs-table": () => import("@shared/logs/_logsTable"),
    "reports-table": () => import("@shared/sites/report/_reportsTable"),
    "site-tokens": () => import("@shared/sites/_siteTokens.vue")
  },
  data() {
    return {
      siteId: this.$route.params.siteId,
      loading: true
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    hasTaskCredits() {
      return this.$store.getters["sites/site/hasTaskCredits"](this.siteId);
    }
  },
  beforeDestroy() {
    this.$store.dispatch("sites/unobserveSite", { siteId: this.siteId });
  },
  async created() {
    try {
      this.loading = true;
      await this.$store.dispatch("sites/observeSite", { siteId: this.siteId });
    } catch {
      this.siteNotFound();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    siteNotFound() {
      this.$router.push(`/admin/sites`);
      this.$toast.open({
        message: `Invalid permissions or site not found`,
        position: "is-bottom",
        queue: false,
        type: "is-danger"
      });
    },
    createTask() {
      this.$store.dispatch("tasks/openAddTaskModal", {
        siteId: this.siteId
      });
    },
    upgradeSubscription() {
      this.$modal.open({
        component: () => import("@shared/contracts/_upgradeModal"),
        parent: this,
        width: 960,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          fqdn: this.site.fqdn,
          siteId: this.siteId,
          userId: this.site.authorId,
          isSubscribed: this.site.isSubscribed
        }
      });
    }
  }
};
</script>
